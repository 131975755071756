import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../components/Layouts/layout'
import SEO from '../components/seo'
import LinkList from '../components/LinkList/LinkList'

const AboutPage = () => {
  const intl = useIntl()

  return (
    <Layout header={<FormattedMessage id='nav.about' />}>
      <SEO
        description={intl.formatMessage({ id: 'metaTags.aboutDescription' })}
        lang={intl.locale}
        title={intl.formatMessage({ id: 'nav.about' })}
      />
      <Container>
        <Row>
          <Col md={12}>
            <LinkList
              links={[
                {
                  path: '/about/our-role/',
                  text: <FormattedMessage id='nav.ourRole' />,
                  internal: true
                },
                {
                  path: '/about/governance/',
                  text: <FormattedMessage id='nav.governance' />,
                  internal: true
                },
                {
                  path: '/about/leadership/',
                  text: <FormattedMessage id='nav.leadership' />,
                  internal: true
                },
                {
                  path: '/about/board-of-directors/',
                  text: <FormattedMessage id='nav.board' />,
                  internal: true
                },
                {
                  path: '/about/research-and-education-program/',
                  text: <FormattedMessage id='nav.research' />,
                  internal: true
                },
                {
                  path: '/about/consumer-advisory-council/',
                  text: <FormattedMessage id='nav.cac' />,
                  internal: true
                },
                {
                  path: '/about/industry-advisory-council/',
                  text: <FormattedMessage id='nav.iac' />,
                  internal: true
                },
                {
                  path: '/about/discipline-and-appeals-committees/',
                  text: <FormattedMessage id='daCommittee.headerI' />,
                  internal: true
                },
                {
                  path: '/about/useful-links/',
                  text: <FormattedMessage id='nav.links' />,
                  internal: true
                }
              ]}
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
export default AboutPage
